@font-face {
    font-family: 'PrivaThreePro';
    src: url('PrivaThreePro.woff2') format('woff2'),
        url('PrivaThreePro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PrivaOnePro';
    src: url('PrivaOnePro.woff2') format('woff2'),
        url('PrivaOnePro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PrivaFourPro';
    src: url('PrivaFourPro.woff2') format('woff2'),
        url('PrivaFourPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

